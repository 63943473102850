import React, { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS file for AOS

import MoveToTopButton from "../../components/moveToTopButton/MoveToTopButton";
import banner1 from "../../assets/images/nextgen-banner-3.webp";
import teamPerson2 from "../../assets/images/teamPerson1.png";
import teamPerson3 from "../../assets/images/teamPerson2.png";
import teamPerson6 from "../../assets/images/teamPerson5.png";
import teamPerson5 from "../../assets/images/teamPerson4.png";
import feature1 from "../../assets/images/featured1.png";
import feature2 from "../../assets/images/featured2.png";
import feature3 from "../../assets/images/featured3.png";
import feature4 from "../../assets/images/featured4.png";
import feature5 from "../../assets/images/featured5.png";
import feature6 from "../../assets/images/featured6.png";

export default function TeamPage() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000, // Animation duration
      offset: 50, // Offset (in pixels) from the original trigger point
      delay: 100, // Delay (in milliseconds) for the animation to start
    });
  }, []);

  return (
    <div>
      <Layout>
        <div className="">
          <div className="">
            <div
              className="md:h-[600px] h-[700px] w-full flex justify-start items-center bg-[#041324]"
              style={{
                background: `url(${banner1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="tracking-wide xl:w-[45%] lg:w-[65%] w-full text-white lg:ml-20 md:p-10 p-6">
                <h2 className="md:text-4xl text-3xl font-bold" style={{"lineHeight" : "45px"}} data-aos="fade-up">
                  Meet the Minds Behind Our Success
                </h2>
                <p className="mt-4" data-aos="fade-up">
                  Our team is a dynamic group of skilled professionals who are
                  passionate about technology and innovation.  Together, we
                  drive our company forward, ensuring that every project is
                  executed with expertise, creativity, and a commitment to
                  excellence.
                </p>
                <ul className=" list-disc mt-4" data-aos="fade-up">
                  <li>Experienced Tech Experts</li>
                  <li>Creative Problem Solvers</li>
                  <li>Collaborative Innovators</li>
                  <li>Customer-Centric Professionals</li>
                </ul>
              </div>
            </div>
            <hr />

            <div className="">
              <h2 className="md:text-[40px] text-3xl font-extrabold text-center mt-20 text-[#4e4d4d]" data-aos="fade-up">
                We’re Featured In
              </h2>
              <div className="mt-16 flex flex-wrap lg:gap-16 gap-10 justify-center">
                <img
                  src={feature1}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
                <img
                  src={feature2}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
                <img
                  src={feature3}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
                <img
                  src={feature4}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
                <img
                  src={feature5}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
                <img
                  src={feature6}
                  alt=""
                  className="lg:h-[100px] lg:w-[100px] h-[70px] w-[70px]" data-aos="fade-up"
                />
              </div>
            </div>

            <div className="mt-28">
              <h2 className="md:text-[40px] text-3xl font-extrabold text-center text-[#4e4d4d]">
                Our Team
              </h2>
            </div>
            {/* Teams */}
            <div className=" flex justify-center md:mt-44 mt-16">
              <div className="lg:w-[90%] w-full">
                <div className="flex lg:flex-row flex-col items-center md:gap-20 gap-10 justify-between">
                  <div className="lg:w-[52%] w-[90%] flex justify-center items-center" data-aos="fade-up">
                    <img
                      src={teamPerson2}
                      className="lg:h-[500px] md:h-[400px] h-[350px] rounded-lg"
                      alt=""
                    />
                  </div>
                  <div className="lg:w-[48%] md:w-[80%] w-[90%] px-3" data-aos="fade-up">
                    <h3 className="text-3xl font-bold">John Hector</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee]">
                        Game Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AMJ Workplace Wellness is a holistic wellness app designed
                      to promote employee health and fitness in the corporate
                      environment.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        80% Increase in Employee Satisfaction
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        AI-driven Personalized Recommendations
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center md:gap-20 gap-10 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[48%] md:w-[80%] w-[90%] px-3" data-aos="fade-up">
                    <h3 className="text-3xl font-bold">Ryan Fredrick</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee]">
                        UI/UX Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AI-powered mobile application designed for transparent,
                      accessible information through a chatbot interface.
                      Developed to enhance interaction realism with advanced
                      Flutter animations, engaging 3D models, and prompt
                      contextual engineering for quick, helpful responses.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Compatible with Other Devices
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="lg:w-[52%] w-[90%] flex justify-center items-center" data-aos="fade-up">
                    <img
                      src={teamPerson3}
                      className="lg:h-[500px] md:h-[400px] h-[350px] rounded-lg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col items-center md:gap-20 gap-10 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[52%] w-[90%] flex items-center justify-center" data-aos="fade-up">
                    <img
                      src={teamPerson6}
                      className="lg:h-[500px] md:h-[400px] h-[350px] rounded-lg"
                      alt=""
                    />
                  </div>
                  <div className="lg:w-[48%] md:w-[80%] w-[90%] px-3" data-aos="fade-up">
                    <h3 className="text-3xl font-bold">Tom Bruce</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee]">
                        Game Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      FSK revolutionizes mobile gaming with its innovative
                      fusion of match-3 puzzles, time management challenges, and
                      captivating role-playing elements.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Seamless UI/UX Design for Corporate Use
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        AI-driven Personalized Recommendations
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col-reverse items-center md:gap-20 gap-10 justify-between md:mt-36 mt-20">
                  <div className="lg:w-[48%] md:w-[80%] w-[90%] px-3" data-aos="fade-up">
                    <h3 className="text-3xl font-bold">Alex Joy</h3>
                    <div className="flex flex-wrap gap-4 font-medium mt-6 text-sm">
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee]">
                        UI/UX Design
                      </span>
                      <span className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[pink]">
                        Mobile App Development
                      </span>
                    </div>
                    <p className="mt-4 tracking-wide">
                      AI-powered mobile application designed for transparent,
                      accessible information through a chatbot interface.
                      Developed to enhance interaction realism with advanced
                      Flutter animations, engaging 3D models, and prompt
                      contextual engineering for quick, helpful responses.
                    </p>
                    <div className="flex flex-col justify-center items-start gap-0 mt-8">
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        30% increase in user engagement within the first month
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Integration with Wearable Devices
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        Real-time Health Tracking and Analysis
                      </div>
                      <br />
                      <div className="md:px-10 px-6 py-2 rounded-full md:text-base text-sm bg-[#eeeeee] border -mt-1">
                        80% Increase in Employee Satisfaction
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="lg:w-[52%] w-[90%] flex items-center justify-center" data-aos="fade-up">
                    <img
                      src={teamPerson5}
                      className="lg:h-[500px] md:h-[380px] h-[350px] rounded-lg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center my-10" data-aos="fade-up">
              <Link
                to="/contact-us"
                className="text-[coral] underline text-lg italic font-medium tracking-wide"
              >
                Want to discuss your project ?
              </Link>
            </div>
          </div>
        </div>
        <MoveToTopButton />
      </Layout>
    </div>
  );
}
